<script setup>
import { useCustomizerStore } from '~/stores/customizer'

const customizer = useCustomizerStore()

// const dark = ref(false);
const dark = computed(() => {
  return customizer.actTheme === 'DARK_BLUE_THEME' ||
    customizer.actTheme === 'DARK_AQUA_THEME' ||
    customizer.actTheme === 'DARK_ORANGE_THEME' ||
    customizer.actTheme === 'DARK_PURPLE_THEME' ||
    customizer.actTheme === 'DARK_GREEN_THEME' ||
    customizer.actTheme === 'DARK_CYAN_THEME'
    ? true
    : false
})
</script>
<script>
export default {
  name: 'MainLogo'
}
</script>
<template>
  <LogoLight v-if="dark" />
  <LogoDark v-else />
</template>
